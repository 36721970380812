import React from 'react';
import {Navbar} from "./component/Navbar/Navbar";
import {Routes, Route, BrowserRouter} from "react-router-dom"
import {Home} from "./pages/Home/Home";
import {Auth} from "./pages/Auth/Auth"
import {Body} from "./component/Body/Body";
import {Account} from "./pages/Account/Account";
import {Download} from "./pages/Download/Download";
import {useDispatch} from "react-redux";
import './App.css';
import {autoLogin} from "./storeFeatures/auth/authSlice";
import {RegConfirm} from "./pages/RegConfirm/RegConfirm";
import {NotFound} from "./pages/NotFound/NotFound";
import {UserAgreement} from "./pages/UserAgreement/UserAgreement";
import {PrivacyPolicy} from "./pages/PrivacyPolicy/PrivacyPolicy";
import {Video} from "./pages/Video/Video";



function App() {

    const dispatch: any = useDispatch();
    dispatch(autoLogin({}));

    return (
        <div className="App">
            <BrowserRouter>
                <Navbar/>
                <Body>
                    <Routes>
                        <Route path='*' element={<NotFound />} />
                        <Route path={'/'} element={<Home/>}></Route>
                        <Route path={'/download/'} element={<Download/>}></Route>
                        <Route path={'/video/'} element={<Video/>}></Route>
                        <Route path={'/privacyPolicy/'} element={<PrivacyPolicy/>}></Route>
                        <Route path={'/userAgreement/'} element={<UserAgreement/>}></Route>
                        <Route path={'/auth/registration/confirm/'} element={<RegConfirm/>}></Route>
                        <Route path={'/account'} element={<Account/>}>
                            <Route path={'/account/:id'} element={<Account/>}></Route>
                        </Route>
                        <Route path={'/auth/:authType'} element={<Auth/>}></Route>
                    </Routes>
                </Body>
            </BrowserRouter>
        </div>
    );
}

/*const Counter = () => {
    let count: any = useSelector((state) => state);
    const dispatch: any = useDispatch();

    return (
        <div>
            <h2>{count}</h2>
            <button onClick={() => dispatch(counterDecrement())}>-</button>
            <button onClick={() => dispatch(counterIncrement())}>+</button>
            <button onClick={() => dispatch(counterReset())}>reset</button>
        </div>
    );
};*/

export default App;
