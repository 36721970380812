import './RegConfirm.scss'
import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {
    emailConfirmation,
    selectAuthStatus
} from "../../storeFeatures/auth/authSlice";
import {useEffect, useRef} from 'react';

export const RegConfirm = () => {

    const {isRegistrationConfirm, authError} = useSelector((state) => selectAuthStatus(state));
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const emailConfirmationHash = searchParams.get("emailConfirmationHash");

    const mounted:any = useRef();
    useEffect(() => {
        if (!mounted.current) {
            // @ts-ignore
            dispatch(emailConfirmation({
                email,
                emailConfirmationHash
            }));
            mounted.current = true;
        }
    });



    return (
        <div className="RegConfirm">
            <h3>Подтверждение email</h3>
            <br/>
            {!authError ? (isRegistrationConfirm ? "Email подтвержден" : "Запрос на подтверждение отправлен, пожалуйста подождите"):""}
            <br/>
            <br/>
            <div style={{color: "red"}}>{authError ? "Ошибка подтверждения " + authError :""}</div>
        </div>
    )
}