import './Home.scss'
import React from "react";
import {NavLink} from "react-router-dom";
/*import MainImg from '../../images/sc3.jpg'
import SkillsImg from '../../images/skills5.jpg'*/

export const Home = () => {

    return (
        <div>
            <div className="Home">
                <h1>
                    MMORPG Кластер - игра 2024 года для ПК, ММОРПГ - это массовая многопользовательская онлайн игра. Игра от первого лица (First Person Action) с большой свободой действий и уклоном в PvP. Игра в раннем доступе!
                </h1>
                <div>

                    {/*                    <noindex>
                    <video
                        width="100%"
                        controls
                        autoPlay={true}
                        muted={true}
                    >
                        <source
                            src="https://download-cluster-thegame.obs.ru-moscow-1.hc.sbercloud.ru/video.mp4"
                            type="video/mp4" />
                        Your browser doesn't support HTML5 video tag.
                    </video>
                    </noindex>*/}
                    <br/>
                    <p><b>Как начать играть?</b><br/>
                        <NavLink to="/auth/registration" className="nav-link-small">Зарегистрируйтесь на
                            сайте!</NavLink><br/>
                        <NavLink to="/download" className="nav-link-small">Скачайте клиента игры (раздел
                            «Играть»).</NavLink><br/><br/>
                        Запустите клиента, указав имя пользователя и пароль.<br/>
                        Создайте персонажа.<br/>
                        Изучайте новый мир, следуйте подсказкам, прислушивайтесь к советам других игроков.<br/>
                        Однако помните: мир в игре жесток, не доверяйте никому.<br/></p>

                    {/*                    <div className="container">
                        <img alt={"main"} src={MainImg}/>
                        <i>В стабе у магазина припасов, а стена за домом переход между узлами ;-)</i>
                        <br/><br/>
                        <img alt={"main"} src={SkillsImg}/>
                        <i>Шпигуем зараженного стрелами и качаем стрельбу из лука</i>
                    </div>*/}

                    <p><b>Внимание!</b> Для проведения технических работ и обновлений сервера игры могут быть
                        временно
                        отключены.</p>

                    <p><b>Мир и сюжет</b><br/>
                        Вы оказались в мире, который называют Кластером. Вы не стали зомби, как большинство
                        попавших
                        сюда. Но теперь вам предстоит выживать,
                        сражаться с зомби и монстрами, противостоять вирусу и искать путь домой.</p>

                    <p><b>Кластер</b><br/>
                        Кластер состоит из отдельных частей (узлов), которые иногда перезагружаются. Каждый раз
                        при
                        перезагрузке узел меняется на новый.
                        Любая часть из мультивселенной переносится в Кластер. Переносится все как есть:
                        ландшафт,
                        здания, люди, вещи. Что это за место? Почему происходит перенос? Никто не знает.
                        Узлы изолированы друг от друга мембраной. Перейти ее можно, но время в мембране
                        замедляется и
                        даже приостанавливается на мгновение в момент перехода. При переходе живое существо
                        окутывает
                        энергетический щит, который защищает на определенное время от внешнего воздействия.
                        Предвестником перегагрузки узла является сгущение тумана. Если это происходит,
                        необходимо срочно
                        покинуть узел кластера, перейдя в другой, остаться на перезагружаемом узле смертельно
                        опасно.
                    </p>

                    <p><b>Вирус: зомби</b><br/>
                        Людей, попавших в Кластер, поражает вирус. Большинство становятся зомби. Но некоторым
                        странно
                        везет: заразившись, они не превращаются в зомби, но вынуждены принимать антивирус.<br/>
                        Единственный смысл зомби — утолить голод, сожрать тех, кто еще не обратился. Зомби
                        мутируют и
                        становятся сильнее, превращаясь в огромных чудовищ.
                    </p>
                    <p><b>Люди: избранные</b><br/>
                        Люди обитают в нескольких стабильных узлах Кластера, где не происходят перезагрузки и
                        переносы.
                        Отсюда они делают вылазки за провизией, амуницией и антивирусом в перезагружаемые
                        узлы.<br/>
                        В Кластере у людей появляются новые способности. Самая важная — возможность вернуться
                        после
                        гибели. Погибший человек воскресает в случайном месте Кластера — как правило, на
                        стабильном узле
                        — и помнит все, что было до гибели.
                    </p>
                    <p><b>Споры: компонент антивируса</b><br/>
                        Внутри зараженных образуються споры, содержащее частичку энергии Кластера. Из этих спор
                        готовят
                        противовирусный препарат, который останавливает и даже обращает вспять течение вируса.
                        Вернуть
                        зомби к нормальной жизни уже нельзя, но людей спасает.
                    </p>

                    <p><b>Стражи мира</b><br/>
                        Кластер следит за скоплениями людей на одном узле. Если людей становится много, их
                        особые
                        способности замедляются. При перенаселении узла появляются Стражи Кластера. Они насылают
                        погодные и пространственные аномалии и не щадят никого.
                    </p>

                    <p><b>PvP</b><br/>
                        Режим игры где один игрок может напасть на другого огораничен только на очень редких
                        стабильных
                        узлах кластера. Походы за припасами в перезагружаемые узлы, может привести к стычкам с
                        другими
                        игроками.
                    </p>

                    <h2>Обращение разработчика</h2>
                    <h3>Как и у любого другого у меня есть определенный опыт, который сформировался
                        из прочитаных книг художественной литературы, игр и кинофильмов.
                        Я бы хотел перечислить основные вещи, которые повлияли на меня и которые так или иначе
                        найдут отражение в игре Cluster. Понимая мой опыт, вы сможете понять куда данный проект
                        может
                        развиватся
                        и что ожидать.
                        Многопользовательские Online игры: Ultima Online, Eve Online, Star Citizen, Lineage2,
                        PlanetSide
                        1-2, DayZ. Игры синглы и кооп: Fallout, Resident Evil, Forest, Green hell, Barotrauma.
                        Кино: Всё
                        везде и сразу, Рик и Морти, Война миров Z, 28 дней спустя. Книги: S-T-I-K-S Человеческий
                        улей
                        (Артем Каменистый), Пикник на обочине (Братья Стругацкие). Второго шанса не будет (Сурен
                        Цормудян)
                        <br/>
                        Все перечисленное, так или иначе повлиявшее на меня, позволило мне сформмулировать
                        основопологающие принципы, которых я собираюсь придерживатся при разработки игры
                        Cluster.</h3>
                    <p><b>8 главных принципов</b></p>
                    <ol>
                        <li>Современный игровой движок</li>
                        <br/>
                        <li>MMORPG с единым миром для всех игроков. Забудьте про выбор сервера из списка,
                            перестаньте
                            растраиватся если Ваш любимый сервер переполнен. На единном сервере вы всегда
                            сможете
                            встретиться с друзьями и делать что-то вместе.
                        </li>
                        <br/>
                        <li>Вид от первого лица — наиболее справедливый и интересный вид игры. Только в таком
                            формате
                            игры все участники равны.
                        </li>
                        <br/>
                        <li>Я предлагаю зайти в игру и начать играть, никого не дожидаясь. Я против игр сессий
                            где всем
                            нужно сначла собратся, а только потом начать играть. Я против изолированных от
                            других
                            игроков рейдов. Все, что нужно, уже в игре. Тот, кого вы ждете, скоро подойдет.
                            Догонит.
                        </li>
                        <br/>
                        <li>PvP без ограничений. В мирных поселениях, стабильных узлах, запрещен лут и
                            причинение вреда
                            здоровью персонажей. Как только вышел за пределы стабильного узла, то монстры и
                            другие
                            игроки могут нападать.
                        </li>
                        <br/>
                        <li>Процедурная генерация (точечно) — способ добавить разнообразия игре. Бывает скучно
                            ходить по
                            тем же самым местам и собирать одни и те же артефакты.
                        </li>
                        <br/>
                        <li>Это не простая песочница. В игре будут участвовать гейм-мастера из команды проекта.
                            Они
                            возьмут на себя роль опасного монстра, случайного попутчика или проведут массовое
                            событие
                            для всех игроков.
                        </li>
                        <br/>
                        <li>Совместная работа — только общаясь с игроками, можно сделать качественный проект.
                            Мне
                            хотелось бы получать от вас обратную связь: совет, рекомендация, конструктивная
                            критика —
                            важно все.
                        </li>
                        <br/>
                    </ol>

                    <p><b>Приоритеты</b></p>
                    <ol>
                        <li>Производительность</li>
                        <li>Геймплей</li>
                        <li>Сторилайн</li>
                        <li>Процедурная генерация</li>
                        <li>AI персонажей</li>
                        <li>Античит</li>
                        <li>Графика</li>
                        <li>Физика</li>
                    </ol>
                    <p>Приоритеты расположены в порядке убывания.<br/><br/>
                        Почему это важно? Если ваше предложение по улучшению графики будет сильно влиять на
                        производительность, то, скорее всего, оно будет отклонено</p>

                    <p><b>В заключении:</b> <br/>
                        Работы много, но шаг за шагом мы придем к релизу. После релиза работа будет продолжена.
                        MMORPG —
                        жанр, который может существовать десятилетиями: при должном обновлении игра будет
                        востребована.
                        Мне интересен процесс разработки и разработка игры — это моя давняя мечта. Уверен что
                        игру я
                        точно сделаю, результаты уже есть, вы уже сейчас можете в нее начать играть.
                        Я рад нашему знакомству и призываю всех общаться с уважением к друг другу.
                        Присоединяйтесь к сообществу в официальном <a className={"discord"}
                                                                      href="https://discord.gg/Ynh3eeRG9s">Discord-канале</a>
                    </p>
                    <br/>
                    <h2>Новости проекта</h2>
                    <div className="News">
                        <h3>16.05.2024</h3>
                        <h3>Перезагрузка узла видна из соседних узлов:</h3>
                        <p>Если идет перезагрузка соседнего узла, то по линии перехода, появляется эффект тумана.</p>
                        <p>Версия клиента 1.010</p>
                    </div>

                    <div className="News">
                        <h3>12.05.2024</h3>
                        <h3>Текстовый чат стал глобальным:</h3>
                        <p>Ранее в чат могли передавать сообщения только внутри узла, связи между узлами не было. Теперь
                            чат стал работать на всех узлах как единое средство общение.</p>
                        <h3>Смена суток стала глобальной:</h3>
                        <p>Ранее время суток было на каждом узле свое. Было странно выйти из стаба ночью, а прийти в
                            соседнюю область на только что перезагруженный узел днем. Это поведение исправлено и время
                            суток стало общим на все узлы кластера.</p>
                        <p>Версия клиента 1.009</p>
                    </div>
                    <div className="News">
                        <h3>09.05.2024</h3>
                        <h3>Меню настроек графики:</h3>
                        <p>На экране входа в игру (ввода логина и пароля) добавлена кнопка настройки, которая открывает
                            окно с графическими настройками игры. Добавлены основные настройки полноэкранный режим или в
                            окне, разрешение, уровень детализации и включение/отключение вертикальной синхронизации.</p>
                        <p>Версия клиента 1.008</p>
                    </div>
                    <div className="News">
                        <h3>04.05.2024</h3>
                        <h3>Внутриигровой голосовой чат:</h3>
                        <p>При нажатии кнопки "V" активируется голосовой чат. Чтобы говорить, необходим микрофон.
                            Слышимость собеседника зависит от расстояния. Слышно примерно на расстоянии двадцати шагов,
                            при увеличении расстояния слышимость плавно снижается.</p>
                        <p>Версия клиента 1.007</p>
                    </div>
                </div>
            </div>
        </div>
    )
}