import {configureStore} from '@reduxjs/toolkit';
import {authReducer} from './storeFeatures/auth/authSlice'
import {accountReducer} from "./storeFeatures/account/accountSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        account: accountReducer
    },
    devTools: true,
});