import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: '@@auth',
    initialState: {
        isFormValid: false,
        formControls: {
            email: {
                value: '',
                type: 'email',
                label: 'Email',
                errorMessage: 'Введите корректный Email',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    email: true
                }
            },
            password: {
                value: '',
                type: 'password',
                label: 'Пароль',
                errorMessage: 'Введите корректный пароль',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 4
                }
            },
            passwordConfirm: {
                value: '',
                type: 'password',
                label: 'Подтвердить пароль',
                errorMessage: 'Введите корректный пароль',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 4
                }
            }

        },
        authUserName: "",
        authUserRole: "",
        authUserToken: "",
        isRegistrationSuccess: false,
        isRegistrationConfirm: false,
        isLoginSuccess: false,
        authError: "",
        registerConfirmRules: false,
    },
    reducers: {
        checkFormForValid: {
            reducer: (state) => {
                let isFormValid = true
                Object.keys(state.formControls).forEach((name) => {
                    isFormValid = state.formControls[name].valid && isFormValid
                })
                state.isFormValid = isFormValid;
            }
        },
        changeValue: {
            reducer: (state, action) => {
                state.isFormValid = action.payload.isFormValid;
                state.formControls = action.payload.formControls;
            }
        },
        changeCheckRulesValue: {
            reducer: (state, action) => {
                state.changeCheckRulesValue = action.payload.registerConfirmRules;
            }
        },
        addPasswordConfirm: {
            reducer: (state) => {
                state.formControls.passwordConfirm = {
                    value: '',
                    type: 'password',
                    label: 'Подтвердить пароль',
                    errorMessage: 'Введите корректный пароль',
                    valid: false,
                    touched: false,
                    validation: {
                        required: true,
                        minLength: 4
                    }
                };
                state.authError = "";
                state.isRegistrationSuccess = false;
            }
        },
        removePasswordConfirm: {
            reducer: (state) => {
                delete state.formControls.passwordConfirm;
                state.authError = "";
                state.isRegistrationSuccess = false;
            }
        },
        authSuccess: {
            reducer: (state, action) => {
                state.authUserName = action.payload.username;
                state.authUserRole = action.payload.role;
                state.authUserToken = action.payload.token;
                state.isLoginSuccess = true;
                state.isRegistrationSuccess = false;
                state.authError = "";
                localStorage.setItem('isLoginSuccess', "true");
                localStorage.setItem('token', action.payload.token);
                localStorage.setItem('username', action.payload.username);
                localStorage.setItem('userRole', action.payload.role);
            }
        },
        registrationSuccess: {
            reducer: (state, action) => {
                state.authUserName = action.payload.username;
                state.authUserRole = "";
                state.authUserToken = "";
                state.authError = "";
                state.isLoginSuccess = false;
                state.isRegistrationSuccess = true;
            }
        },
        authError: {
            reducer: (state, action) => {
                state.authUserName = "";
                state.authUserRole = "";
                state.authUserToken = "";
                state.authError = action.payload.message;
                state.isLoginSuccess = false;
                state.isRegistrationSuccess = false;
            }
        },
        emailConfirmationError: {
            reducer: (state, action) => {
                state.authError = action.payload.message;
            }
        },
        emailConfirmationSuccess: {
            reducer: (state) => {
                state.isRegistrationConfirm = true;
                state.authError = "";
            }
        },
        clearAuthError: {
            reducer: (state) => {
                state.authError = "";
            }
        },
        autoLogin: {
            reducer: (state) => {
                const username = localStorage.getItem("username");
                if (username) {
                    state.authUserName = username;
                }

                const token = localStorage.getItem("token");
                if (token) {
                    state.authUserToken = token;
                }

                const userRole = localStorage.getItem("userRole");
                if (token) {
                    state.authUserRole = userRole;
                }

                const isLoginSuccess = localStorage.getItem("isLoginSuccess");
                if (isLoginSuccess) {
                    state.isLoginSuccess = isLoginSuccess;
                }
            }
        },
        logout: {
            reducer: (state) => {
                localStorage.clear();
                state.authUserName = "";
                state.authUserRole = "";
                state.authUserToken = "";
                state.isRegistrationSuccess = false;
                state.isLoginSuccess = false;
                state.authError = "";
            }
        }
    }
});

export const authRequest = createAsyncThunk(
    '@@auth/authRequest',
    async (authInfo, {
        dispatch,
    }) => {

        const loginUrl = "/api/v1/auth/login";
        const registrationUrl = "/api/v1/auth/registration";
        let finalUrl = "";
        let userPass = {};
        userPass["username"] = authInfo.payloadAuth.username;
        userPass["password"] = authInfo.payloadAuth.password;

        let authMethod = authInfo.payloadAuth.authTypeAction;

        if (authMethod === "login") {
            finalUrl = loginUrl;
        }

        if (authMethod === "registration") {
            finalUrl = registrationUrl;
            userPass["confirmPassword"] = authInfo.payloadAuth.confirmPassword;
            userPass["registerConfirmRules"] = authInfo.payloadAuth.registerConfirmRules;
        }

        const res = await fetch(finalUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userPass)
        })

        let data;

        if (res.status === 500) {
            data = {message: res.statusText}
        } else {
            data = await res.json();
        }

        if (res.status === 200) {

            if (authMethod === "login") {
                dispatch(authSuccess(data))
            }
            if (authMethod === "registration") {
                dispatch(registrationSuccess(data))
            }
        }
        if (res.status !== 200) {
            dispatch(authError(data))
        }
    }
);

export const emailConfirmation = createAsyncThunk(
    '@@auth/emailConfirmation',
    async (authInfo, {
        dispatch,
    }) => {
        const emailConfirmationUrl = "/api/v1/auth/registration/confirm";

        let emailConfirmation = {};

        emailConfirmation["email"] = authInfo.email;
        emailConfirmation["emailConfirmationHash"] = authInfo.emailConfirmationHash;


        const res = await fetch(emailConfirmationUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(emailConfirmation)
        })

        let data;

        if (res.status === 500) {
            data = {message: res.statusText}
        } else {
            data = await res.json();
        }

        if (res.status === 200) {
            dispatch(emailConfirmationSuccess(data))
        }
        if (res.status !== 200) {
            dispatch(emailConfirmationError(data))
        }
    }
);


export const authReducer = authSlice.reducer;

export const {
    authSuccess,
    authError,
    changeValue,
    changeCheckRulesValue,
    addPasswordConfirm,
    removePasswordConfirm,
    checkFormForValid,
    registrationSuccess,
    autoLogin,
    logout,
    emailConfirmationError,
    emailConfirmationSuccess
} = authSlice.actions;

export const selectAuthUser = (state) => ({
    username: state.auth.authUserName,
    userRole: state.auth.authUserRole,
})
export const selectAuthStatus = (state) => ({
    isLoginSuccess: state.auth.isLoginSuccess,
    isRegistrationSuccess: state.auth.isRegistrationSuccess,
    isRegistrationConfirm: state.auth.isRegistrationConfirm,
    authError: state.auth.authError
})

export const selectAllAuth = (state) => ({
    authAll: state.auth
})

export const selectCheckRulesValue = (state) => ({
    checkRulesValue: state.auth.changeCheckRulesValue
})
