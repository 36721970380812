import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const accountSlice = createSlice({
    name: '@@acc',
    initialState: {
        accountError:"",
        account: {
            accountName: '',
            role: '',
            registrationDate: '',
            emailIsConfirmed: false,
            characters: []
        }
    },
    reducers: {
        getSuccess: {
            reducer: (state, action) => {
                state.account = action.payload;
            }
        },
        getError: {
            reducer: (state, action) => {
                state.accountError = action.payload.message;
            }
        }
    }
});

export const accountRequest = createAsyncThunk(
    '@@acc/accountRequest',
    async (accountInfo, {
        dispatch,
    }) => {

        const url = "/api/v1/user/account";

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer_'+localStorage.getItem("token")
            }
        })

        let data;

        if (res.status !== 200) {
            data = {message: res.statusText}
        } else {
            data = await res.json();
        }

        if (res.status === 200) {
            dispatch(getSuccess(data))
        }
        if (res.status !== 200) {
            dispatch(getError(data))
        }
    }
);

export const accountReducer = accountSlice.reducer;

export const {
    getSuccess,
    getError
} = accountSlice.actions;

export const selectAccountStatus = (state) => ({
    accountError: state.account.accountError
})

export const selectAccount = (state) => ({
    account: state.account
})