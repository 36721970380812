import './Download.scss'
import Iframe from "../../component/Iframe/Iframe";
import React from "react";
import {NavLink} from "react-router-dom";
export const Download = () => {

    return (
        <div className="Download">
            <p>Последняя версия клиента для Windows</p>
            <a href="https://download-cluster-thegame.obs.ru-moscow-1.hc.sbercloud.ru/Cluster.zip" download="Cluster.zip">Скачать Cluster.zip</a>

            <br/>
            <br/>
            Для запуска игры требуется регистрация. <NavLink to="/auth/registration" className="nav-link-small">Зарегистрируйтесь пожалуйста!</NavLink> Логин он же email и пароль потребуются для входа в игру.
            <br/>
            <br/>
            <i>Cluster.zip это дистрибутив игры. Необходимо скачать архив дистрибутива, распаковать и запустить Cluster.exe. При запуске может быть показано предупреждение. Это предупреждение возникает из-за того что exe файл не подписан цифровой подписью издателя. На данном этапе разработки игры такой подписи нет. Для продолжения необходимо нажать кнопку "подробнее", а затем "Выполнить в любом случае". Просим отнестись с пониманием. Если возникли вопросы можете задать их в официальном <a className={"discord"} href="https://discord.gg/Ynh3eeRG9s">Discord-канале</a></i>
            <br/>
            <br/>
            <p><b>Если есть желание поддержать проект, то это можно сделать</b><br/><br/>
                <Iframe title={'Donate'} src={'https://yoomoney.ru/quickpay/fundraise/button?billNumber=Bia4gAJnEJc.230808&'} height={32} width={165}/>
            </p>

        </div>
    )
}