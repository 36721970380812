import './Video.scss'
import React from "react";

export const Video = () => {

    return (
        <div>
            <div className="Video">
                <video
                    width="100%"
                    /*controls*/
                    autoPlay={true}
                    muted={true}
                    playsInline={true}
                    disablePictureInPicture={true}
                    loop={true}
                >
                    <source
                        src="https://download-cluster-thegame.obs.ru-moscow-1.hc.sbercloud.ru/video.mp4"
                        type="video/mp4"/>
                    Your browser doesn't support HTML5 video tag.
                </video>
            </div>
        </div>
    )
}
