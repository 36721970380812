import React from "react";
import './Button.scss'

const Button = (props: any) => {

    return (
        <button
            onClick={props.onClick}
            className={"Button"}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    )
}
export default Button