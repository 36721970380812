import './NotFound.scss'
import React from "react";
import {useNavigation} from "react-router-dom";

export const NotFound = () => {

    let nav = useNavigation()


    return (
        <div className="Home">
            <p>404 Страница не найдена</p>
            res.status(404).send('Not found');
        </div>
    )
}