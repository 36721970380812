import React from "react";
import './Checkbox.scss'
import {NavLink} from "react-router-dom";

const Checkbox = (props: any) => {

    return (
        <label>
            <input type={"checkbox"} onChange={props.onChange}/> Я соглашаюсь со следующими установленными <br/> правилами: <NavLink to="/userAgreement" className="nav-link-small">Пользовательское соглашение cluster-thegame.ru</NavLink>, <NavLink to="/privacyPolicy" className="nav-link-small">Политика конфиденциальности cluster-thegame.ru</NavLink>
        </label>

    )
}
export default Checkbox