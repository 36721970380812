import './PrivacyPolicy.scss'

export const PrivacyPolicy = () => {
    return (
        <div className="PrivacyPolicy">
            <h2>Политика конфиденциальности</h2>

            <p>Настоящая Политика является частью и должна рассматриваться совместно с Соглашением. В случае каких-либо
                противоречий между настоящей Политикой и Соглашением, настоящая Политика будет иметь преимущественную
                силу.</p>

            <h3>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h3>
            <p>В настоящей Политике определения, если они написаны с заглавной буквы, имеют значения, присвоенные им в
                Соглашении. Для целей настоящей Политики приведенные ниже термины имеют следующие значения:</p>
            <p>«Иные данные» – информация, необходимая для функционирования Игры, в том числе Веб-сайта и/или
                определенного Сервиса, а именно: (a) данные о технических средствах (устройствах), технологическом
                взаимодействии с Игрой; (b) информация, автоматически получаемая при доступе к Игре с
                использованием cookies; (c) информация, полученная в результате действий Пользователя в Игре, в
                частности, информация о добавлении какого-либо контента; (d) информация, полученная в результате
                действий других Пользователей на Игре; (e) обобщенная аналитическая информация об использовании
                Игры.</p>
            <p>«Разработчик» - Raewsky. Обладатель исключительного права на Игру</p>
            <p>«Игра» – Компьютерная игра cluster-thegame. Не азартная игра. Являеться программой для ЭВМ, в том числе
                для персональных
                компьютеров (ПК) принадлежащая Разработчику cluster-thegame.ru</p>
            <p>«Пользователь» – пользователь сети Интернет, который создал Учетную запись в Игре, а также
                удовлетворяет всем критериям, перечисленным в Соглашении.</p>
            <p>«Соглашение» – настоящий юридический документ, который размещен по адресу
                https://cluster-thegame.ru/userAgreement, определяющий условия и порядок использования Игры.</p>
            <p>«Учетные данные» – следующая информация: (a) данные о Пользователе, предоставляемые Пользователем для
                создания Учетной записи в процессе регистрации в Игре; (b) дополнительная информация, заполняемая
                Пользователем при редактировании своей Учетной записи в Игре; (c) данные, дополнительно предоставляемые
                Пользователем по запросу Разработчика в целях исполнения Разработчиком обязательств перед Пользователем,
                вытекающих из Соглашения или Специальных правил.</p>
            <h3>2. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
            <p>Предметом регулирования настоящей Политики являются отношения между Разработчиком, который является
                правообладателем Игры, в том числе Веб-сайта и Сервисов, как это определено в Соглашении, и
                Пользователями по поводу обработки информации в процессе использования Игры.</p>
            <p>Отношения, связанные с обработкой информации, включая, но не ограничивая сбор, использование, хранение,
                распространение и защиту информации о Пользователях, регулируются настоящей Политикой, а также
                действующим законодательством Российской Федерации. Перед тем как начать использование Игры Пользователь
                обязан ознакомиться с настоящей Политикой, а также со всеми применимыми правилами.</p>
            <p>При регистрации, каждом доступе и/или фактическом использовании Игры, Пользователь соглашается с
                условиями настоящей Политики, а также с условиями Соглашения, Специальных правил используемых им
                Сервисов, которые могут как быть приложениями к Соглашению, так и быть размещены на страницах (в
                разделах) соответствующих Сервисов, в редакциях, которые действовали на момент фактического
                использования таких Сервисов.</p>

            <h3>3. СОСТАВ ИНФОРМАЦИИ</h3>
            <p>Обрабатываемая Разработчиком информация, включает в себя Учетные данные и Иные данные.</p>
            <p>Обрабатываемые РАзработчиком Учетные и Иные данные в объеме, необходимом и достаточном для их отнесения в
                соответствии с действующим законодательством Российской Федерации к персональным данным, обрабатываются
                Разработчиком как персональные данные на условиях настоящей Политики.</p>
            <p>В таблице ниже более подробно указан перечень Учетных данных и Иных данных, которые обрабатываются
                Разработчиком, в том числе в рамках Игры и конкретного Сервиса, в целях, указанных в п.4 настоящей
                Политики:</p>

        <div className="Table">
            <p><strong>Собираемая информация</strong></p>
            <p><strong>Учетные данные</strong>, а именно:</p>
            <p>1) Данные о Пользователе, предоставляемые Пользователем для создания Учетной записи в
                процессе регистрации в Игре, в том числе ваш адрес электронной почты, никнеймы, список
                друзей, когда вы
                регистрируетесь в Игре через свои учетные записи в социальных сетях или магазинах
                приложений и/или подключаете свою социальную учетную запись к Игре (например, Twitter,
                Google, YouTube), ииная информация, необходимая для исполнения Соглашения
                сПользователем.</p>
            <p>2) Дополнительная информация, заполняемая Пользователем при редактировании своей Учетной
                записи в процессе использования Игры, в том числе ваш адрес электронной почты, ваше имя
                и
                фамилия, никнейм, пол, дата рождения, аватар и языковые
                предпочтения, ваши идентификаторы в социальных сетях, мессенджерах, идентификаторы
                магазинов
                приложений, список друзей ииная информация, необходимая для исполнения Соглашения
                сПользователем.</p>
            <p>3) Данные, дополнительно предоставляемые Пользователем по запросу Разработчика (фамилия,
                имя;
                дата рождения; дата, время и место регистрации/авторизации в Игре; информация об
                Интернет провайдере; информация о совершенных Пользователем транзакциях в процессе
                использования Игры; адрес электронной почты, информация об учетных записях в социальных
                сетях и другие дополнительные данные, которые вы можете предоставить нам. Мы можем
                предпринять дополнительные шаги по проверке,
                если мы считаем это целесообразным, для проверки вашей Учетной записи).</p>

            <p><strong>Цель обрбаотки</strong></p>
            <p>Разработчик использует эту информацию для управления и администрирования Игрой, включая
                предоставление Пользователю услуг Игры. Разработчик использует эти данные, чтобы он мог
                выполнить свои обязательства перед Пользователями при использовании ими Игры (например,
                в тех случаях, когда Пользователю необходимо пройти процедуру авторизации в Игре).</p>

            <p><strong>Собираемая информация</strong></p>
            <p><strong>Иные данные</strong>, а именно:</p>
            <p>4) Информация об активности Пользователя во время использования Игры, в том числе
                информация, которую вы публикуете на любых форумах и/или в чатах в рамках Игры*;
                информация, полученная в результате использования вами функций оплаты (например, первые
                и
                последние четыре цифры номера вашей банковской карты), информация о платежах (дата,
                сумма,
                вид платежа, статус платежа), информация о приобретенных товарах и услугах
                (наименование,
                количество), информация о статусе активации приобретенных товаров и услуг); информация,
                полученная в результате ваших поведенческих действий при использовании Игры (включая
                ваши игровые действия и достижения, значки)**; ваш IP-адрес, время регистрации в Игре,
                идентификаторы устройств, настройки страны и языка, модель устройства и используемая
                операционная система, системная информация и данные об использовании памяти,
                установленные
                вами приложения, тип браузера, ваш интернет-провайдер и/или оператор телефонной сети,
                тип
                сети, разрешение экрана и размер ОЗУ и иная информация, необходимая для исполнения
                соглашения сПользователем.</p>
            <p>* В зависимости от чата или форума, информация, которую вы публикуете, может быть
                доступна
                некоторым или всем другим пользователям Игры.</p>
            <p>** Эта информация может быть доступна другим пользователям Игры (например, в списках
                лидеров).</p>
            <p>5) Информация, полученная врезультате действий других Пользователей в Игре
                (вчастности, информация, размещенная в чатах и на форумах другими Пользователями
                ииная информация, необходимая для исполнения Соглашения).</p>
            <p><strong>Цель обрбаотки</strong></p>
            <p>Исполнение Соглашения с Пользователем по предоставлению Игры и Сервисов. В частности,
                для осуществления коммуникации, улучшения работы Игры, управления и администрирования
                Игрой и другое.</p>


            </div>

            <p>Функционалом и правилами использования отдельных Сервисов Пользователю может быть предоставлена
                возможность размещать любую иную информацию, помимо указанной в пункте 3 настоящей Политики, если она не
                противоречит требованиям действующего законодательства. Разработчик не имеет цели обрабатывать такую
                информацию, а также биометрические и специальные категории персональных данных. Размещая информацию,
                Пользователь понимает, что она может быть доступна неопределенному кругу пользователей сети Интернет с
                учетом настроек желаемого уровня конфиденциальности.</p>
            <p>Если функциональные возможности используемого Сервиса позволяют отражать информацию о Пользователе в его
                Учетной записи, которая доступна для просмотра другим Пользователям такого Сервиса или пользователям
                сети Интернет, то Пользователь соглашается на отражение информации о нем в его Учетной записи.
                Пользователь соглашается, что информация, включая Учетные и Иные данные, а также иная информация может
                быть доступна другим Пользователям соответствующего Сервиса и/или другим пользователям сети Интернет с
                учетом существующего функционала Сервиса (который может изменяться время от времени Разработчиком).</p>
            <p>Разработчик не проверяет предоставленные Пользователем Учетные данные и не может судить об их
                достоверности, а также о том, обладает ли Пользователь достаточной правоспособностью для предоставления
                Ученых данных. Тем не менее Разработчик исходит из того, что Пользователь предоставляет достоверные и
                достаточные Учетные данные, а также своевременно обновляет их. Регистрируя Учетную запись в Игре, либо
                получая доступ к Игре без регистрации, Пользователь подтверждает, что достиг допустимого возраста
                использования Игры в соответствии с применимым законодательством.</p>
            <p>ОБРАБОТКА COOKIES. Сookies представляют собой небольшие фрагменты данных, которые сайты запрашивают у
                браузера, используемого на компьютере или мобильном устройстве Пользователя. Сookies хранятся локально
                на компьютере или мобильном устройстве пользователя сети Интернет. Разработчик собирает и обрабатывает
                cookies в отношении Пользователей, посещающих Игру и Веб-сайт.</p>
            <p>Сookies обрабатываются Разработчиком исключительно с целью, которая указана в пункте 4 настоящей
                Политики, на условиях и в порядке, определенных настоящей Политикой, в частности на основании данных,
                полученных с помощью файлов cookies, Разработчик разрабатывает наиболее полезный функционал для Игры,
                доступный Пользователю, проводит статистические исследования, исправляет ошибки в Игре и тестирует новые
                функции для повышения производительности Игры, персонализирует их и показывает наиболее релевантную для
                Пользователя информацию.</p>
            <p>Пользователь может отказаться от обработки cookies в настройках своего браузера. В указанном случае
                Разработчик будет использовать только те cookies, которые строго необходимы для функционирования Игры и
                предоставления ее функциональных возможностей.</p>

            <h3>4. УСЛОВИЯ ОБРАБОТКИ ИНФОРМАЦИИ</h3>
            <p>ПРИНЦИПЫ ОБРАБОТКИ ИНФОРМАЦИИ:</p>
            <p>- законности целей и способов обработки информации;</p>
            <p>- добросовестности Разработчика;</p>
            <p>- соответствия целей обработки информации целям, заранее определенным и заявленным при ее сборе, а также
                полномочиям Разработчика;</p>
            <p>- соответствия объема и характера обрабатываемой информации, способов ее обработки целям обработки
                информации;</p>
            <p>- недопустимости объединения созданных для несовместимых между собой целей баз данных, содержащих
                информацию Пользователей.</p>
            <p>ЦЕЛИ ОБРАБОТКИ ИНФОРМАЦИИ</p>
            <p>Разработчик осуществляет обработку Учетных данных и Иных данных Пользователей в целях исполнения
                заключенного с Пользователями Соглашения по использованию Игры и Сервисов, а равно Специальных правил
                относительно отдельных Сервисов.</p>
            <p>СБОР ИНФОРМАЦИИ</p>
            <p>Сбор Учетных данных Пользователя осуществляется при его регистрации в Игре путем заполнения Пользователем
                регистрационной формы или иными доступными способами, в том числе с использованием иного сервиса
                третьего лица, а также в дальнейшем при редактировании Пользователем ранее предоставленной информации
                либо при дополнении по своей инициативе Учетных данных (если применимо) с помощью инструментария
                Игры.</p>
            <p>Сбор Иных данных осуществляется Разработчиком самостоятельно в процессе использования Пользователем Игры.
                В ряде случаев сбор Иных данных Пользователя начинается с момента получения Пользователем доступа к
                Сервису (например, при загрузке интернет-страницы или запуске приложения) до момента его регистрации на
                Игре.</p>
            <p>ОБРАБОТКА И ПЕРЕДАЧА ИНФОРМАЦИИ</p>
            <p>Учетные и Иные данные Пользователей не передаются каким-либо третьим лицам, за исключением случаев, прямо
                предусмотренных настоящей Политикой, иными применимыми правилами, а также применимым
                законодательством.</p>
            <p>Разработчик может передавать Учетные и Иные данные Пользователей третьим лицам, с соблюдением цели,
                указанной в пункте 4 настоящей Политики. К таким третьим лицам могут относиться:</p>
            <p>- лица, которые оказывают Разработчку услуги, связанные с размещением и отображением рекламы в
                программах, продуктах или сервисах, которые принадлежат таким лицам или контролируются ими (например,
                владельцы сайтов и приложений, рекламные сети и другие лица и т.п.);</p>
            <p>- любой орган государственной власти или местного самоуправления, которому Разработчик обязан
                предоставлять информацию в соответствии с применимым законодательством по соответствующему запросу.</p>
            <p>Пользователь соглашается на обработку собираемых Игрой данных, в объеме, указанном в разделе 3 настоящей
                Политики, который(ые) использует Пользователь, в целях улучшения качества предоставляемых Игрой услуг, а
                именно: для удовлетворение интересов Пользователя путем показа релевантной интересам Пользователя
                информации, таргетирования точной рекламы (показа релевантной его интересам информации), формирования
                обобщенной статистической/ аналитической информации на основе использования Игры и сервиса(ов).</p>
            <p>Принимая во внимание изложенное, а также учитывая соблюдение Разработчиком целей обработки, указанных в
                пункте 4 настоящей Политики, Пользователь также соглашается и поручает Разработчику осуществлять
                следующие действия:</p>
            <p>- обработку, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
                изменение), сопоставление, извлечение, использование, блокирование, удаление/уничтожение Учетных и Иных
                данных Пользователя; </p>
            <p>Не допускается извлечение и использование Учетных и Иных данных Пользователей, в том числе в коммерческих
                целях, без разрешения Разработчика.</p>
            <p>ХРАНЕНИЕ ИНФОРМАЦИИ</p>
            <p>Учетные и Иные данные Пользователей хранятся на территории Российской Федерации, при этом хранение
                осуществляется исключительно на электронных носителях, а обработка – с использованием автоматизированных
                систем, за исключением случаев, когда неавтоматизированная обработка Учетных и Иных данных необходима в
                связи с исполнением требований применимого законодательства.</p>
            <p>Учетные и Иные данные хранятся до достижения целей их обработки.</p>
            <p>СРОКИ ХРАНЕНИЯ ИНФОРМАЦИИ</p>
            <p>Учетные и Иные данные хранятся Разработчиком в течение срока действия Соглашения между Пользователем и
                Разработчиком об использовании Игры, а после прекращения действия Соглашения – в течение срока,
                необходимого и установленного действующим законодательством Российской Федерации.</p>
            <p>ПРЕКРАЩЕНИЕ ОБРАБОТКИ ИНФОРМАЦИИ</p>
            <p>При достижении целей обработки информации Разработчик прекращает обработку Учетных и Иных данных одним из
                способов, предусмотренных Федеральным законом от 27 июля 2006 года N 152-ФЗ «О персональных данных».</p>

            <h3>5. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЕЙ ПРИ ОБРАБОТКЕ ИХ ИНФОРМАЦИИ</h3>

            <p>Пользователи вправе:</p>
            <p>- получать бесплатный доступ к информации о себе посредством просмотра Учетной записи;</p>
            <p>- с помощью инструментария Игры установить в отношении информации о себе желаемый уровень
                конфиденциальности (условия доступа к информации) с учетом функционала Игры;</p>
            <p>- самостоятельно вносить изменения и исправления в информацию о себе путем редактирования информации в
                Учетной записи, при условии, что такие изменения и исправления содержат актуальную и достоверную
                информацию с учетом функционала Игры;</p>
            <p>- удалять информацию о себе путем редактирования информации в Учетной записи; при этом удаление
                Пользователем определенной информации о себе из Учетной записи Пользователя может привести к
                невозможности предоставления Пользователю доступа к Игре;</p>
            <p>- требовать от азработчика уточнения информации о Пользователе, ее блокирования или уничтожения в случае,
                если такая информация является неполной, устаревшей, недостоверной, необоснованно полученной или не
                является необходимой для заявленной цели обработки и если функционал Сервиса не позволяет Пользователю
                самостоятельно удалить такую информацию;</p>
            <p>- на основании запроса получать от Разработчика информацию, касающуюся обработки информации о себе;</p>
            <p>- оказаться от обработки персональных данных путем направления Разработчику в порядке, предусмотренном в
                разделе 8 настоящей Политики, соответствующего запроса.</p>

            <h3>6. МЕРЫ ПО ЗАЩИТЕ ИНФОРМАЦИИ</h3>

            <p>Разработчик принимает технические и организационно-правовые меры в целях обеспечения защиты информации о
                Пользователях от неправомерного или случайного доступа к ней, уничтожения, изменения, блокирования,
                копирования, распространения, а также от иных неправомерных действий.</p>
            <p>Технические меры безопасности реализованы Разработчиком с учетом требований применимого законодательства,
                современного уровня техники, характера обрабатываемой информации и рисков, связанных с ее
                обработкой.</p>
            <p>Информация обрабатывается преимущественно автоматически.</p>

            <h3>7. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ РАЗРАБОТЧИКА</h3>
            <p>Разработчик не несет ответственности за разглашение и распространение информации о Пользователе другими
                Пользователями Сервисов или другими пользователями сети Интернет в случае, если такие лица получили
                доступ к указанной информации в соответствии с выбранным Пользователем настройками уровня
                конфиденциальности Игры, либо в случае нарушения Пользователем сохранности его логина и/или пароля или
                иных необходимых для авторизации данных.</p>

            <h3>8. ОБРАЩЕНИЯ ПОЛЬЗОВАТЕЛЕЙ</h3>
            <p>Сведения об обрабатываемых Разработчиком данных, в том числе персональных данных Пользователя, в связи с
                использованием им Игры предоставляются Пользователю или его представителю при обращении (запросе).</p>
            <p>Запросы направляются а электронную почту Разработчика или в иной форме, предусмотренной действующим
                законодательством Российской Федерации.</p>
            <p>Пользователь вправе отозвать согласие на обработку своих персональных данных путем направления
                Разработчику письменного заявления на электронную почту Разработчика в соответствии с требованиями
                действующего законодательства.</p>

            <h3>9. ИЗМЕНЕНИЯ И ДОПОЛНЕНИЯ НАСТОЯЩЕЙ ПОЛИТИКИ</h3>
            <p>Настоящая Политика может быть изменена Разработчиком в любое время, за исключением случаев, прямо
                предусмотренных применимым законодательством. Любое изменение настоящей Политики должно быть доведено до
                сведения Пользователей, в частности, посредством опубликования обновленной редакции на Веб-сайте и/или
                направления Пользователям уведомления любым доступным Разработчиком способом. Обновленная Политика
                вступает в силу со дня ее опубликования, если иное прямо не предусмотрено применимым законодательством.
                Пользователю рекомендуется периодически проверять Веб-сайт на наличие уведомлений о таких изменениях.
                Отказ Пользователя от действий по ознакомлению не может служить основанием для невыполнения обязательств
                Пользователя и несоблюдения Пользователем ограничений, установленных Политикой. Если Пользователь не
                согласен с изменениями, Пользователь вправе прекратить использование Игры. Продолжение использования
                Игры Пользователем считается принятием любых пересмотренных условий.</p>
            <p>Настоящая Политика регулируется и толкуется в соответствии с законодательством Российской Федерации.
                Вопросы, не урегулированные настоящей Политикой, подлежат разрешению в соответствии с законодательством
                Российской Федерации.</p>


        </div>
    )
}