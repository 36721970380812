import './UserAgreement.scss'

export const UserAgreement = () => {
    return (
        <div className="UserAgreement">
            <h2>Пользовательское соглашение</h2>

            <p>
                Настоящее Соглашение регулирует отношения между лицензиаром Разработчиком cluster-thegame.ru и
                лицензиатами (Пользователями) по поводу использования Игры, включая, помимо прочего, Сервисы и
                Веб-сайт.
            </p>

            <p>Присоединяясь к условиям Соглашения, Пользователь:</p>

            <p>
                (A) подтверждает, что он прочитал, понял и безоговорочно принял условия настоящего Соглашения, а также
                условия других Специальных правил (как это определено ниже), и гарантирует, что он будет соблюдать их в
                рамках использования Игры;
            </p>
            <p>
                (B) признает и соглашается с тем, что он независимо оценил необходимость использования Игры и не
                полагается на какие-либо заверения, гарантии или заявления, отличные от тех, которые прямо изложены в
                настоящем Соглашении;
            </p>
            <p>
                (С) заверяет и гарантирует, что он может на законных основаниях заключать договоры (например,
                Пользователь
                достиг возраста дееспособности, предусмотренного применимым законодательством). Если Пользователь
                является
                несовершеннолетним, такой Пользователь должен ознакомиться с Соглашением с помощью своих
                родителей/законных
                представителей. Разработчик cluster-thegame.ru рекомендует родителям или законным представителям
                контролировать онлайн-активности
                своих детей. Для защиты частной жизни детей разработчик cluster-thegame.ru рекомендует родителям или
                законным представителям
                контролировать, чтобы их дети никогда не раскрывали свои персональные данные без предварительного
                согласия
                их родителей или законных представителей. Разработчик cluster-thegame.ru оставляет за собой право
                ограничить
                доступ к Игре
                и/или отдельным Сервисам в зависимости от возраста и может разрешать несовершеннолетним регистрацию
                только с письменного согласия родителей/законных представителей. Разработчик cluster-thegame.ru
                оставляет за
                собой право
                запросить письменное доказательство наличия согласия родителей/законных представителей в отношении
                любого
                Пользователя или потенциального Пользователя, относительно которого Разработчик cluster-thegame.ru имеет
                предположения,
                что он может быть несовершеннолетним. Во всех случаях использование Игры
                несовершеннолетними должно
                осуществляться под ответственность их родителей или законных представителей, и предполагается, что любое
                использование Игры было одобрено ими; и
            </p>
            <p>
                (D) заверяет и гарантирует, что он не осуществляет доступ к программному продукту и его сервисам с
                помощью VPN («виртуальная частная
                сеть») или аналогичных инструментов.
            </p>
            <p>В противном случае использование Игры и сервисов cluster-thegame.ru запрещено.</p>
            <p>
                В настоящем Соглашении следующие определения, если они написаны с заглавной буквы, имеют следующие
                значения:
            </p>

            <h3>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h3>

            <p>
                В настоящем Соглашении следующие определения, если они написаны с заглавной буквы, имеют следующие
                значения:
            </p>
            <p>«Веб-сайт» – https://cluster-thegame.ru, а равно все и любые веб-сайты Сервисов, указанных на данном
                веб-сайте, и все домены и поддомены следующих уровней.</p>
            <p>«Внутриигровые предметы» – виртуальные внутриигровые ценности, в том числе объекты, товары, элементы
                игрового мира и Внутриигровая валюта, а равно статусы, признаки или иные характеристики виртуальных
                внутриигровых предметов и/или персонажей, внутриигровые достижения которые могут быть доступны для
                приобретения в Игре и/или связанном с ней сервисе Игры как на возмездной основе, так и в виде поощрения
                за определенную активность Пользователя в Игре</p>
            <p>«Внутриигровая валюта» – виртуальная внутриигровая ценность, которая не имеет денежной стоимости и не
                подлежит денежной оценке, хотя и имеет цену на момент приобретения.</p>
            <p>«Игра» – Компьютерная игра cluster-thegame. Не азартная игра. Являеться программой для ЭВМ, в том числе
                для персональных
                компьютеров (ПК) принадлежащая Разработчику cluster-thegame.ru</p>
            <p>«Разработчик» - Raewsky. Обладатель исключительного права на Игру</p>
            <p>«Неприемлемый контент» – любой вид контента или поведения в порядке использования Игры, который является
                либо незаконным, либо неприемлемым в соответствии с общепринятыми моральными нормами, включая, среди
                прочего, следующие примеры:</p>
            <p>(i) участие в или содействие любой незаконной деятельности или деятельности, которая нарушает права
                других лиц;</p>
            <p>(ii) контент, который является или может быть обоснованно расценен как незаконный, вредный,
                оскорбительный, порочащий, клеветнический, непристойный или иным образом нежелательный и
                неприемлемый;</p>
            <p>(iii) предоставление информации, которая является ложной, вводящей в заблуждение или неточной;</p>
            <p>(iv) разглашение любой личной или частной информации другого Пользователя или любого другого лица, или
                иное вторжение в частную жизнь другого лица;</p>
            <p>(v) злоупотребление, домогательство, преследование, угрозы, представление на всеобщее обозрение или
                запугивание какого-либо лица или какой-либо организации;</p>
            <p>(vi) ненормативная лексика или использование уничижительного, дискриминационного, ненавистнического или
                чрезмерного изобразительного языка;</p>
            <p>(vii) любой контент, который может нанести вред несовершеннолетним;</p>
            <p>(viii) распространение или пропаганда ненависти, нетерпимости, дискриминации, вреда, ненависти на расовой
                или этнической почве, насилия, преступлений или войны;</p>
            <p>(ix) оскорбляющий, вульгарный, сексуально откровенный или порнографический контент;</p>
            <p>(х) пропаганда употребления алкоголя, табака или любых наркотических или запрещенных веществ,
                использования огнестрельного оружия;</p>
            <p>(xi) передача программных вирусов, червей или любого другого вида вредоносного программного
                обеспечения;</p>
            <p>(xii) несогласованная с получателем или неавторизованная реклама, рекламные материалы, «нежелательная
                почта», «спам», «цепные письма», «пирамидальные схемы» или любые другие формы нежелательной рекламы;</p>
            <p>(xiii) взлом;</p>
            <p>(xiv) нарушение каких-либо прав интеллектуальной собственности или незаконное предоставление/раскрытие
                информации (инсайдерской информации, конфиденциальной информации, другой частной или охраняемой законом
                информации);</p>
            <p>(xv) другой неприемлемый контент или поведение.</p>
            <p>«Политика конфиденциальности» – Политика конфиденциальности cluster-thegame.ru, которая размещена по
                адресу https://cluster-thegame.ru/privacyPolicy, определяющая условия и порядок обработки информации в
                процессе использования Игры</p>
            <p>«Пользователь» – пользователь сети Интернет, который создал Учетную запись в Игре, а также удовлетворяет
                всем критериям, перечисленным в Соглашении.</p>
            <p>«Сервисы» – разделы Игры</p>
            <p>«Соглашение» – настоящий юридический документ, который размещен по адресу
                https://cluster-thegame.ru/userAgreement, определяющий условия и порядок использования Игры.</p>
            <p>«Специальные правила» – особые условия использования соответствующих Сервисов.</p>
            <p>«Учетная запись» – аккаунт Пользователя в Игре.</p>
            <p>«Пользовательский контент» – любые комментарии, текстовые или голосовые сообщения, фотографии,
                графические изображения, видео, звуки, музыкальные произведения и другие материалы, данные и информация,
                а также ссылки на них, загруженные, переданные, опубликованные или иным образом распространенные
                Пользователем другим Пользователям и/или Разработчиком во время использования Игры (за исключением
                персональных данных Пользователя, на которые распространяется Политика конфиденциальности).</p>

            <h3>2. ОБЩИЕ ПОЛОЖЕНИЯ</h3>

            <p>По условиям Соглашения, Разработчик предоставляет Пользователю с помощью веб-интерфейса и/или приложений
                для персональных компьютеров и иных инструментов право на использование Игры путем предоставления
                удаленного доступа к Игре через информационно-телекоммуникационную сеть «Интернет». Разработчик
                оставляет за собой право брать плату за предоставление доступа к Игре</p>

            <p>Пользователь не имеет права:</p>
            <p>
                - изменять, адаптировать, декомпилировать, дизассемблировать или иным образом изменять Игру и/или любые ее
                компоненты и любой доступ к интеллектуальной собственности, доступ к которой предоставляется
                Пользователю в ходе использования Игры;
            </p>
            <p>
                - распространять в коммерческих или некоммерческих целях Игру и/или любые ее компоненты и любой доступ к
                интеллектуальной собственности, доступ к которой предоставляется Пользователю в ходе использования Игры,
                делать копии или снимки экрана либо посредством распространения на физических носителях или путем
                предоставления их для загрузки третьим лицам из сети Интернет;
            </p>
            <p>
                - переводить Игру и/или любой из ее компонентов на другие языки или создавать другие производные работы в
                отношении Игры и/или любого из ее компонентов;
            </p>
            <p>
                - пытаться обойти любые меры безопасности, принятые в Игре, включая блокировку доступа по IP-адресу;
            </p>
            <p>
                - использовать Игру любым другим способом, не предусмотренным настоящим Соглашением, Специальными
                правилами, а также за рамками обычного процесса использования.
            </p>

            <p>Пользователь соглашается и признает, что любые и все права, которые прямо не предоставлены в соответствии
                с настоящим Соглашением, сохраняются за Разработчиком.</p>

            <p>Права интеллектуальной собственности, предоставленные по настоящему Соглашению, предоставляются по
                лицензии, но не продаются, и не предоставляют никаких прав или титулов на Игру.</p>

            <p>Настоящее Соглашение дополняется и должно рассматриваться совместно с Политикой конфиденциальности.</p>

            <h3>3. УЧЕТНАЯ ЗАПИСЬ ПОЛЬЗОВАТЕЛЯ</h3>
            <p>Для использования Игры Пользователю необходимо создать Учетную запись, в частности, заполнить
                регистрационную форму или создать Учетную запись с помощью своей учетной записи в социальных сетях или
                в Игре третьих лиц.</p>
            <p>При регистрации Учетной записи Пользователь может заполнить регистрационную форму данными, которые он
                считает достаточными для своей идентификации в Игре в качестве уникального пользователя, а также поля
                регистрационной формы, заполнение которых является обязательным для Пользователя при использовании
                Игры.</p>
            <p>В случае утраты пароля от учетной записи, созданной Пользователем, которая используется для доступа к
                Игре, такой пароль может быть восстановлен Пользователем исключительно способами, которые предусмотрены
                применимыми правилами Игры.</p>
            <p>Разработчик оставляет за собой право изменять и дополнять способы создания Учетной записи, если иное
                прямо не предусмотрено применимым законодательством.</p>
            <p>Разработчик не может нести ответственности и гарантировать безопасность Учетной записи Пользователя в
                случаях: передачи Пользователем третьим лицам (умышленно или по неосторожности) пароля и Учетных данных;
                доступа третьих лиц к Учетной записи Пользователя вследствие использования Пользователем форм,
                расположенных на внешних Интернет-сайтах, для доступа к Игре, с использованием программных средств,
                позволяющих осуществить подбор и/или раскодирование пароля; доступа третьих лиц к Учетной записи
                Пользователя путем простого подбора пароля и Учетных данных; невыполнение Пользователем рекомендаций,
                указанных в Соглашении, если иное прямо не предусмотрено применимым законодательством.</p>

            <h3>4. УСЛОВИЯ ОПЛАТЫ</h3>


            <p>Пользователь признает, что Разработчик может предоставить Пользователю возможность приобрести как на
                возмездной, так и на безвозмездной основе права на дополнительные Внутриигровые предметы, в том числе
                Внутриигровую валюту, в рамках Игры</p>
            <p>Внесение оплаты осуществляется Пользователем в валюте соответствующей территории, согласно установленному
                в Игре порядку конвертации, через поддерживаемые способы оплаты. Список доступных для оплаты способов
                приведен в соответствующих разделах Игры. Размер оплаты определяется Разработчиком. Разработчик впрарве
                привлекать третьих лиц для обеспечения проведения платежей. С условиями предоставления такими лицами
                способов оплаты, а также размерами комиссии Пользователь ознакомляется до момента оплаты.</p>
            <p>Внутриигровая валюта не является средством платежа и служит единственной цели в качестве средства обмена
                на другие Внутриигровые предметы. По общему правилу, Внутриигровую валюту нельзя обменять на денежные
                средства или другие ценности, за исключением других Внутриигровых предметов в ходе обычного
                использования Игры. Любая неиспользованная Внутриигровая валюта не может быть конвертирована обратно в
                денежные средства. В случае предоставления функционалом Игры или Сервисов Разработчика, Пользователям
                может быть разрешено обмениваться Внутриигровыми предметами друг с другом, в том числе на Внутриигровую
                валюту.</p>
            <p>Разработчик не гарантирует, что:</p>

            <p>(i) желаемые Пользователем Внутриигровые предметы Игры будут доступны во время зачисления их на его
                Учетную запись,</p>
            <p>(ii) Пользователь сможет использовать Внутриигровые предметы Игры в течение неопределенного или желаемого
                периода,</p>
            <p>(iii) Пользователь сможет обменять Внутриигровую валюту на какие-либо или определенные Внутриигровые
                предметы,</p>
            <p>(iv) характеристики или предполагаемое использование Внутриигровых предметов Игры останутся неизменными в
                течение всего использования Игры, или будут соответствовать ожиданиям или предпочтениям
                Пользователя.</p>
            <p>Разработчик не несет ответственности за потерю Пользователем в течение использования Игры Внутриигровых
                предметов, в том числе Внутриигровой валюты, полученных в результате использования Игры.</p>
            <p>Принимая во внимание техническую сложность Игры и ресурсов, используемых для ее функционирования,
                Разработчик осуществляет регулярную диагностику Игры во время ее технического обслуживания. Разработчик
                вправе удалить из Учетной записи Пользователя Внутриигровые предметы, в том числе Внутриигровую валюту
                Игры, которые уже отображаются в Учетной записи Пользователя, в случае, если проведенная вышеуказанная
                диагностика выявит, что Внутриигровые предметы, в том числе Внутриигровая валюта Игры, отображались в
                Учетной записи Пользователя ошибочно, в том числе, в результате дефекта или ошибки в Игре, либо как
                следствие мошеннических действий любых Пользователей или третьих лиц, а равно если наличие указанных
                Внутриигровых предметов, в том числе Внутриигровой валюты Игры может повлечь за собой некорректную
                работу Игры. </p>


            <h3>5. КОДЕКС ПОВЕДЕНИЯ</h3>
            <p>В ходе использования Игры Пользователь обязуется не осуществлять следующие действия:</p>
            <p>- загружать, передавать, публиковать или иным образом распространять файлы, содержащие вирусы, троянские
                программы, червей или любое другое вредоносное программное обеспечение или программы, которые могут
                нарушить работу компьютера или нанести ущерб имуществу других Пользователей, или иным образом нарушить
                бесперебойную работу Игры;</p>
            <p>- использовать Игру для публикации или распространения любых нежелательных рекламных объявлений, опросов,
                рекламных материалов, «нежелательной почты», «спама», цепных писем, «пирамидальных схем» или любых
                других форм неправомерных запросов, дублирования или нежелательных сообщений (коммерческих или
                иных);</p>
            <p>- раскрывать и/или публиковать личные данные других пользователей;</p>
            <p>- нарушать любые применимые законы или нормативные акты, настоящее Соглашение, Специальные правила или
                лицензионные соглашения Игры.</p>

            <p>Размещая любой Пользовательский контент в форумах, чатах или других виртуальных пространствах,
                предназначенных для общения, Пользователь обязуется не загружать, не передавать, не публиковать и не
                распространять иным образом Неприемлемый контент, включая:</p>
            <p>- Пользовательский контент, который нарушает любые права интеллектуальной собственности, включая любые
                права, лицензии или согласия, существующие на основании авторских прав, патентов, товарных знаков
                (вместе со всем гудвиллом, связанным с такими товарными знаками), знаков обслуживания, прав на базу
                данных и прав на извлечение данных, зарегистрированных и незарегистрированных промышленных образцов,
                прав на макеты схем и прав на топографию полупроводников, коммерческих секретов, прав на
                конфиденциальность, будь то зарегистрированных или нет, заявок и прав на продление в отношении любого из
                вышеупомянутых и всех других аналогичных прав, признанных в любой части мира;</p>
            <p>- эротический или порнографический Пользовательский контент;</p>
            <p>- Пользовательский контент, который является оскорбительным для любого другого лица и/или наносит ущерб
                третьим лицам или публичной морали;</p>
            <p>- незаконный, вредный, опасный или непристойный Пользовательский контент, а также Пользовательский контент,
                который содержит информацию дискриминационного характера по отношению к другим лицам по признаку пола,
                расы, национальности, религиозных убеждений, сексуальной ориентации и другим запрещенным основаниям;</p>
            <p>- Пользовательский контент коммерческого рекламного характера или привлекающий внимание к каким-либо
                товарам или услугам;</p>
            <p>- Пользовательский контент, который содержит персональную информацию или конфиденциальные данные о
                каком-либо лице, или иным образом нарушает законные права (такие как права на неприкосновенность частной
                жизни и публичность) любого лица.</p>
            <p>Мы приветствуем дружелюбный и открытый тон общения и призываем наших пользователей придерживаться его в
                общении Игре. Размещая любой Пользовательский контент в форумах, чатах или других виртуальных
                пространствах, предназначенных для общения, Пользователь также обязуется:</p>
            <p>- не использовать «ботов» или другие автоматизированные методы сбора информации о Пользователях;</p>
            <p>- не беспокоить, не угрожать, не преследовать, не вводить в заблуждение и не беспокоить других
                Пользователей;</p>
            <p>- следовать общим правилам поведения в публичных дискуссиях, избегая взаимных нападок и оскорблений;</p>
            <p>- не оскорблять, не унижать, не травить и/или не преследовать других пользователей и администрацию Игры и
                Сервисов.</p>

            <h3>6. САНКЦИИ</h3>
            <p>Разработчик самостоятельно устанавливает факт нарушения Пользователем Соглашения, в том числе Специальных
                правил. В случае нарушения Пользователем Соглашения, в том числе Специальных правил, Разработчик имеет
                право применить следующие санкции к Пользователю, в зависимости от степени нарушения, совершенного
                Пользователем, и его неблагоприятного влияния на других Пользователей:</p>
            <p>- выдать предупреждения в любой форме, в том числе по электронной почте и/или личным сообщением в Учетной
                записи Пользователя;</p>
            <p>- удалить любой Пользовательский контент;</p>
            <p>- переименовать, только если это необходимо (например, оскорбительное название), любой элемент созданный
                и/или размещенный Пользователем в Игре;</p>
            <p>- временно ограничить некоторый функционал Учетной записи;</p>
            <p>- приостановить доступ к одной или нескольким Учетным записям и/или дополнительным учетным записям
                Пользователя в отдельных Сервисах (если применимо) в полном объеме;</p>
            <p>- ограничить использование отдельных Сервисов полностью или частично;</p>
            <p>- блокировать IP-адреса, MAC-адреса или прокси-серверы, используемые для доступа к Игре;</p>
            <p>- удалить Учетную запись.</p>

            <p>Разработчик обязуется приложить разумные усилия с целью предоставить Пользователю разъяснения касательно
                того, какие условия настоящего Соглашения были нарушены Пользователем, в результате чего Разработчиком
                были применены санкции. Разработчик не обязан предъявлять Пользователю документальное подтверждение
                нарушения.</p>
            <p>Разработчик вправе запретить Пользователю регистрировать новые Учетные записи в случае нарушения
                Пользователем настоящего Соглашения. В случае если Разработчик обнаружит, что Пользователь имеет
                несколько Учетных записей, Разработчик оставляет за собой право применить вышеуказанные санкции ко всем
                Учетным записям такого Пользователя.</p>
            <p>В отношении конкретного Сервиса и/или иной части Игры Разработчик оставляет за собой право использовать
                доступные каналы связи с Пользователями для предоставления разъяснений и уточнения списка санкций,
                которые Разработчик вправе применить к Пользователю, в зависимости от тяжести совершенного Пользователем
                нарушения и его негативного влияния на других Пользователей.</p>

            <h3>7. ОТКАЗ ОТ ГАРАНТИЙНЫХ ОБЯЗАТЕЛЬСТВ</h3>
            <p>ИГРА ПРЕДОСТАВЛЯЕТСЯ НА УСЛОВИЯХ «КАК ЕСТЬ». ВСЛЕДСТВИЕ ЭТОГО ПОЛЬЗОВАТЕЛИ СОГЛАШАЮТСЯ С ТЕМ, ЧТО ИГРА
                МОЖЕТ НЕ СООТВЕТСТВОВАТЬ ИХ ИНДИВИДУАЛЬНЫМ ПРЕДПОЧТЕНИЯМ И ОЖИДАНИЯМ. РАЗРАБОТЧИК ПРИЛОЖИТ ВСЕ
                КОММЕРЧЕСКИ РАЗУМНЫЕ УСИЛИЯ ДЛЯ ОБЕСПЕЧЕНИЯ НЕПРЕРЫВНОЙ РАБОТЫ ИГРЫ, СООТВЕТСТВЕННО ПОЛЬЗОВАТЕЛИ
                СОГЛАШАЮТСЯ С ТЕМ, ЧТО ИГРА МОЖЕТ СОДЕРЖАТЬ ОШИБКИ И МОЖЕТ ПРЕРЫВАТЬСЯ. РАЗРАБОТЧИК ИМЕЕТ ПРАВО (И ЭТО
                ЯВЛЯЕТСЯ СУЩЕСТВЕННЫМ УСЛОВИЕМ ИСПОЛЬЗОВАНИЯ ПОЛЬЗОВАТЕЛЯМИ ИГРЫ) ПРИОСТАНОВИТЬ ИЛИ ПРЕКРАТИТЬ РАБОТУ
                ИГРЫ И/ИЛИ ДОСТУП К ИГРЕ, ИЛИ ЛЮБОЙ ЕЕ ЧАСТИ ДЛЯ ВСЕХ ИЛИ НЕКОТОРЫХ ПОЛЬЗОВАТЕЛЕЙ В ЛЮБОЕ ВРЕМЯ. В
                МАКСИМАЛЬНОЙ СТЕПЕНИ, ДОПУСКАЕМОЙ ПРИМЕНИМЫМ ЗАКОНОДАТЕЛЬСТВОМ, РАЗРАБОТЧИК ОТКАЗЫВАЕТСЯ ОТ ЛЮБЫХ ЯВНЫХ
                ИЛИ ПОДРАЗУМЕВАЕМЫХ ГАРАНТИЙ ТОЧНОСТИ, АКТУАЛЬНОСТИ, БЕЗОПАСНОСТИ, ОТСУТСТВИЯ ВИРУСОВ, ОШИБОК,
                ЗАКОННОСТИ И/ИЛИ ДОСТОВЕРНОСТИ ИНФОРМАЦИИ, ДАННЫХ, МАТЕРИАЛОВ, А ТАКЖЕ СЕРВИСОВ, ПРЕДОСТАВЛЯЕМЫХ
                РАЗРАБОТЧИКОМ ИЛИ ДРУГИМИ ПОЛЬЗОВАТЕЛЯМИ. РАЗРАБОТЧИК НЕ ГАРАНТИРУЕТ, ЧТО ПРОИЗВОДИТЕЛЬНОСТЬ
                ПЕРСОНАЛЬНЫХ КОМПЬЮТЕРОВ ИЛИ ДРУГИХ УСТРОЙСТВ ПОЛЬЗОВАТЕЛЕЙ БУДЕТ ДОСТАТОЧНОЙ ДЛЯ ИСПОЛЬЗОВАНИЯ ИГРЫ.
                ПОЛЬЗОВАТЕЛЯМ РЕКОМЕНДУЕТСЯ ЗАРАНЕЕ ОПРЕДЕЛИТЬ ТРЕБОВАНИЯ К КОМПЬЮТЕРНОЙ СИСТЕМЕ ДЛЯ ИГРЫ И/ИЛИ СЕРВИСА
                И ОПРЕДЕЛИТЬ, СООТВЕТСТВУЕТ ЛИ КОМПЬЮТЕРНАЯ СИСТЕМА ЭТИМ ТРЕБОВАНИЯМ.</p>

            <h3>8. ОТВЕТСТВЕННОСТЬ</h3>
            <p>В МАКСИМАЛЬНОЙ СТЕПЕНИ, РАЗРЕШЕННОЙ ПРИМЕНИМЫМ ЗАКОНОДАТЕЛЬСТВОМ, РАЗРАБОТЧИК НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ЗА
                ПРЯМЫЕ ИЛИ КОСВЕННЫЕ УБЫТКИ, УПУЩЕННУЮ ВЫГОДУ, УБЫТКИ, ВОЗНИКШИЕ В РЕЗУЛЬТАТЕ УТРАТЫ И/ИЛИ УНИЧТОЖЕНИЯ
                ДАННЫХ, УБЫТКИ, ВОЗНИКШИЕ В РЕЗУЛЬТАТЕ УТРАТЫ/ПРЕКРАЩЕНИЯ/ПРИОСТАНОВЛЕНИЯ ДОСТУПА К УЧЕТНЫМ ЗАПИСЯМ
                ПОЛЬЗОВАТЕЛЕЙ, ПОТЕРИ ПОЛЬЗОВАТЕЛЕМ ДОСТИЖЕНИЙ ИЛИ ПРОГРЕССА В ИГРЕ, А ТАКЖЕ
                ПОТЕРЮ/УНИЧТОЖЕНИЯ/ИЗМЕНЕНИЯ ИНФОРМАЦИИ, РАЗМЕЩЕННОЙ ПОЛЬЗОВАТЕЛЕМ НА ФОРУМАХ ИЛИ ЧАТАХ ИГРЫ. В ЛЮБОМ
                СЛУЧАЕ, ОТВЕТСТВЕННОСТЬ РАЗРАБОТЧИКА ПЕРЕД ПОЛЬЗОВАТЕЛЯМИ БУДЕТ ОГРАНИЧЕНА КОМПЕНСАЦИЕЙ ЗА ФАКТИЧЕСКИЙ
                УЩЕРБ, КОТОРАЯ НЕ БУДЕТ ПРЕВЫШАТЬ 500 РОССИЙСКИХ РУБЛЕЙ. ПОЛЬЗОВАТЕЛЬ НЕ МОЖЕТ ПОЛУЧИТЬ КОМПЕНСАЦИЮ ЗА
                УПУЩЕННУЮ ВЫГОДУ НИ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ. РАЗРАБОТЧИК НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ЗА ЛЮБЫЕ ПРЯМЫЕ ИЛИ
                КОСВЕННЫЕ УБЫТКИ, УПУЩЕННУЮ ВЫГОДУ, УБЫТКИ ПОЛЬЗОВАТЕЛЕЙ ИЛИ ТРЕТЬИХ ЛИЦ, ВЫЗВАННЫЕ:</p>
            <p>(A) ИСПОЛЬЗОВАНИЕМ ИЛИ НЕВОЗМОЖНОСТЬЮ ИСПОЛЬЗОВАНИЯ ИГРЫ И/ИЛИ СЕРВИСОВ;</p>
            <p>(B) НЕСАНКЦИОНИРОВАННЫМ ДОСТУПОМ ТРЕТЬИХ ЛИЦ К ЛИЧНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ, ВКЛЮЧАЯ, СРЕДИ ПРОЧЕГО,
                УЧЕТНУЮ ЗАПИСЬ ПОЛЬЗОВАТЕЛЯ; А ТАКЖЕ</p>
            <p>(C) ЗАЯВЛЕНИЯМИ ИЛИ ПОВЕДЕНИЕМ ЛЮБОГО ТРЕТЬЕГО ЛИЦА В ИГРЕ, НА ВНУТРИИГРОВЫХ ФОРУМАХ ИЛИ В ЧАТАХ, ИЛИ В
                КОММЕНТАРИЯХ НА ВЕБ-САЙТЕ. РАЗРАБОТЧИК НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ЗА ЛЮБЫЕ ЗАДЕРЖКИ В ИСПОЛНЕНИИ
                ОБЯЗАТЕЛЬСТВ ИЛИ НЕИСПОЛНЕНИЕ ОБЯЗАТЕЛЬСТВ, ВЫЗВАННЫЕ ПРИЧИНАМИ, НАХОДЯЩИМИСЯ ВНЕ РАЗУМНОГО КОНТРОЛЯ
                РАЗРАБОТЧИКА, ВКЛЮЧАЯ, ПОМИМО ПРОЧЕГО, ЛЮБОЕ НЕИСПОЛНЕНИЕ ОБЯЗАТЕЛЬСТВ ПО НАСТОЯЩЕМУ СОГЛАШЕНИЮ ИЗ-ЗА
                НЕПРЕДВИДЕННЫХ ОБСТОЯТЕЛЬСТВ ИЛИ ПРИЧИН, НЕ ЗАВИСЯЩИХ ОТ РАЗРАБОТЧИКА, ТАКИХ КАК СТИХИЙНЫЕ БЕДСТВИЯ,
                ВОЙНЫ, ТЕРРОРИЗМ, БЕСПОРЯДКИ, ЭМБАРГО, ДЕЙСТВИЯ ГРАЖДАНСКИХ ИЛИ ВОЕННЫХ ВЛАСТЕЙ, ПОЖАРЫ, НАВОДНЕНИЯ,
                АВАРИИ, СБОИ СЕТЕВОЙ ИНФРАСТРУКТУРЫ, ЗАБАСТОВКИ ИЛИ НЕХВАТКА ТРАНСПОРТНЫХ СРЕДСТВ, ТОПЛИВА, ЭНЕРГИИ,
                РАБОЧЕЙ СИЛЫ ИЛИ МАТЕРИАЛОВ.</p>

            <h3>9. БЕЗОПАСНОСТЬ ДАННЫХ И ИНФОРМАЦИИ</h3>
            <p>С правилами защиты персональных данных можно ознакомиться в Политике конфиденциальности (как определено в
                разделе 1).</p>
            <p>Разработчик очень заботится о защите персональных данных. Персональные данные, собранные Разработчиком в
                контексте настоящего документа, подлежат автоматизированной обработке в соответствии с действующим
                законодательством. Вся информация, собранная в рамках функционирования Игры, регистрируется
                Разработчиком, который является контролером данных. Это очень важно для функционирования предлагаемых
                Разработчиком программ для ЭВМ.</p>

            <p>Информация, предоставленная Пользователем любым способом, должна быть точной. Хотя Разработчик делает все
                возможное для обеспечения конфиденциальности данных и внедрила соответствующих технические и
                организационные меры, чтобы обеспечить и показать, что обработка осуществляется согласно правилам о
                защите данных, Пользователь понимает, что никакие меры безопасности не являются совершенными и такие
                меры можно обойти.</p>
            <p>Пользователь понимает и признает, что даже после удаления данных и Пользовательского контента,
                предоставленных Пользователем, такие данные или Пользовательский контент могут оставаться доступными в
                кэше или веб-архивах, а также в результатах поиска поисковых систем, и также могут быть доступны другим
                лицам, если другие Пользователи скопировали и сохранили данные Пользователя или Пользовательский
                контент.</p>
            <p>Разработчик не может контролировать действия других Пользователей, с которыми Пользователь хочет
                поделиться данными своей Учетной записи (логином и паролем). Поэтому Разработчик не может гарантировать,
                что какой-либо Пользовательский контент, который Пользователь размещает в Игре, не будет доступен для
                просмотра посторонним лицам.</p>
            <p>Разработчик не несет ответственности за любые обходные пути, предпринятые в Игре в обход мер
                безопасности.</p>

            <p>Разработчик может использовать технологии аутентификация Пользователей, в том числе с использованием
                файлов cookies, а также использовать указанные технологии в маркетинговых целях для изучения
                предпочтений Пользователей. При этом такая идентификация носит неперсонифицированный и обобщенный
                характер, Разработчик не отслеживает действий отдельных Пользователей и не осуществляет передачу
                сведений о них.</p>
            <p>В пределах функционирования Игры и с соблюдением применимого законодательства обеспечивается тайна
                сообщений и соблюдается конфиденциальность информации о Пользователях, за исключением случаев,
                предусмотренных законодательством Российской Федерации.</p>

            <h3>10. ЗДОРОВЬЕ ПОЛЬЗОВАТЕЛЕЙ</h3>
            <p>Настоящим Пользователь подтверждает, что он понимает, понимает и соглашается с тем, что Игра может
                содержать аудио-и/или видеоэффекты, которые при определенных обстоятельствах могут вызвать у людей,
                склонных к эпилептическим или иным расстройствам центральной нервной системы, ухудшение таких
                состояний.</p>
            <p>Пользователь понимает и соглашается с тем, что постоянное использование персонального компьютера в
                течение длительного периода времени может привести к различным осложнениям физического состояния,
                включая, помимо прочего, проблемы со зрением, сколиоз, различные формы неврозов, а также другие
                нежелательные и негативные последствия для организма Пользователя.</p>
            <p>Настоящим Пользователь гарантирует, что он будет использовать Игру только в течение разумного периода
                времени с перерывами на отдых и что Пользователь будет принимать другие меры, предписанные его врачом
                для защиты своего здоровья.</p>
            <p>Пользователи должны соблюдать следующие меры предосторожности:</p>
            <p>- Не играть, если устали или недосыпают.</p>
            <p>- Играть на большом расстоянии от экрана.</p>
            <p>- Играть в освещенной комнате и уменьшите яркость экрана.</p>
            <p>- Делать перерывы от десяти (10) до пятнадцати (15) минут каждый час.</p>
            <p>ПРЕДУПРЕЖДЕНИЕ: НЕКОТОРЫЕ ЛЮДИ ПОДВЕРЖЕНЫ ЭПИЛЕПТИЧЕСКИМ ПРИПАДКАМ, ВКЛЮЧАЯ, В НЕКОТОРЫХ СЛУЧАЯХ, ПОТЕРЮ
                СОЗНАНИЯ, ОСОБЕННО ПРИ ВОЗДЕЙСТВИИ СИЛЬНЫХ СВЕТОВЫХ СТИМУЛЯЦИЙ (БЫСТРАЯ ПОСЛЕДОВАТЕЛЬНОСТЬ ИЗОБРАЖЕНИЙ
                ИЛИ ПОВТОРЕНИЕ ПРОСТЫХ ГЕОМЕТРИЧЕСКИХ ФИГУР, ВСПЫШЕК ИЛИ ЭКСПОЗИЦИЙ). ТАКИЕ ЛЮДИ ПОДВЕРГАЮТСЯ РИСКУ
                ПРИПАДКОВ, КОГДА ОНИ ИГРАЮТ В ОПРЕДЕЛЕННЫЕ ВИДЕОИГРЫ, СОДЕРЖАЩИЕ ТАКИЕ СВЕТОВЫЕ СТИМУЛЯЦИИ; КОМПАНИЯ
                НАСТОЯТЕЛЬНО РЕКОМЕНДУЕТ ПОЛЬЗОВАТЕЛЯМ ПРОКОНСУЛЬТИРОВАТЬСЯ СО СВОИМ ВРАЧОМ ПЕРЕД ИСПОЛЬЗОВАНИЕМ ИГРЫ.
                РОДИТЕЛИ ТАКЖЕ ДОЛЖНЫ УДЕЛЯТЬ ОСОБЕННО ПРИСТАЛЬНОЕ ВНИМАНИЕ СВОИМ ДЕТЯМ, КОГДА ОНИ ИГРАЮТ В ВИДЕОИГРЫ.
                ЕСЛИ У ПОЛЬЗОВАТЕЛЯ ПОЯВЛЯЕТСЯ ОДИН ИЗ СЛЕДУЮЩИХ СИМПТОМОВ: ГОЛОВОКРУЖЕНИЕ, ПРОБЛЕМЫ СО ЗРЕНИЕМ,
                СОКРАЩЕНИЕ ГЛАЗ ИЛИ МЫШЦ, ДЕЗОРИЕНТАЦИЯ, НЕПРОИЗВОЛЬНЫЕ ДВИЖЕНИЯ ИЛИ СУДОРОГИ ИЛИ МГНОВЕННАЯ ПОТЕРЯ
                СОЗНАНИЯ, ПОЛЬЗОВАТЕЛЬ ДОЛЖЕН НЕМЕДЛЕННО ПРЕКРАТИТЬ ИГРАТЬ И ОБРАТИТЬСЯ К ВРАЧУ ИЛИ ЕГО РОДИТЕЛИ ДОЛЖНЫ
                ДОБИТЬСЯ ЭТОГО.</p>

            <h3>11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
            <p>ПОПРАВКИ, ОБНОВЛЕНИЯ И ИЗМЕНЕНИЯ</p>
            <p>Разработчик имеет право в любое время по своему усмотрению изменять любые функционал и содержание Игры, если иное прямо не предусмотрено применимым законодательством.</p>
            <p>В целях улучшения Игры и своих Сервисов Разработчик оставляет за собой право автоматически (без отдельного запроса согласия Пользователя) делать технические обновления и вносить изменения в Игру или любой из ее компонентов. В целях обеспечения эффективности этих обновлений и изменений и возможности расширения использования Игры Пользователь настоящим соглашается на внедрение автоматических обновлений и изменений. В случае, если это повлечет за собой уменьшение прав пользователя, Разработчик уведомит Пользователей о таком изменении, и в этом случае уведомленный пользователь имеет право прекратить использование своей Учетной записи в Игре и расторгнуть настоящее Соглашение.</p>
            <p>Настоящее Соглашение может быть изменено Разработчком в любое время, за исключением случаев, прямо предусмотренных применимым законодательством. Любое изменение настоящего Соглашения должно быть доведено до сведения Пользователей, в частности, посредством опубликования обновленной редакции на Веб-сайте и/или направления Пользователям уведомления любым доступным Разработчиком способом. Обновленное Соглашение вступает в силу со дня его опубликования, если иное прямо не предусмотрено применимым законодательством. Пользователю рекомендуется периодически проверять Веб-сайт на наличие уведомлений о таких изменениях. Отказ Пользователя от действий по ознакомлению не может служить основанием для невыполнения обязательств Пользователя и несоблюдения Пользователем ограничений, установленных Соглашением. Если Пользователь не согласен с изменениями, Пользователь вправе прекратить использование Игры. Продолжение использования Игры Пользователем считается принятием любых пересмотренных условий.</p>
            <p>Если какое-либо положение Соглашения является или становится незаконным или не имеющим исковой силы, это положение должно применяться в максимально допустимой степени и/или быть изменено для достижения максимально возможного эффекта первоначального условия, а остальные положения Соглашения остаются в полной силе и сохраняют действие.</p>
            <p>СРОК ДЕЙСТВИЯ, ПРИОСТАНОВЛЕНИЕ И ПРЕКРАЩЕНИЕ ДЕЙСТВИЯ</p>
            <p>Срок действия настоящего Соглашения начинается с даты, когда Пользователь впервые выражает свое согласие с настоящим Соглашением, и оно будет действовать до тех пор, пока его действие не будет прекращено в соответствии с настоящим Соглашением.</p>
            <p>Разработчик имеет право прекратить действие настоящего Соглашения для Пользователя в любое время с немедленным вступлением в силу без возмещения каких-либо затрат, убытков или возврата, полученного по Соглашению, если иное не предусмотрено применимым законодательством, в частности, среди прочего, если Пользователь нарушает какое-либо положение применимого законодательства или нарушает настоящее Соглашение, в том числе Специальные правила, или если Пользователь не использует свою Учетную запись в Игре в течение 12 (двенадцати) и более месяцев, или если Разработчик не может продолжать предоставлять Пользователю Игру по техническим или законным коммерческим причинам.</p>
            <p>Пользователь имеет право в любое время, без уведомления Разработчика и без объяснения причин, прекратить использование своей Учетной записи в Игре. </p>




        </div>
    )
}