import './Account.scss'
import {useEffect, useRef} from 'react';
import {accountRequest, selectAccount, selectAccountStatus} from "../../storeFeatures/account/accountSlice";
import {useDispatch, useSelector} from "react-redux";

export const Account = () => {

    const {accountError} = useSelector((state) => selectAccountStatus(state));
    const {account} = useSelector((state) => selectAccount(state));

    const dispatch = useDispatch();

    const mounted:any = useRef();
    useEffect(() => {
        if (!mounted.current) {
            // @ts-ignore
            dispatch(accountRequest());
            mounted.current = true;
        }
    });

    function renderCharacter (characters:any){
        return characters.map((character:any, index:any)=>{
            return (
                <div key={index}>
                    ID: {character.id}<br/>
                    Имя персонажа: {character.characterName}<br/>
                    Позиция в мире ( <b>X</b>: {character.worldPositionX} <b>Y</b>: {character.worldPositionY} <b>Z</b>: {character.worldPositionZ})<br/>
                    Инвентарь: {character.characterInventory}<br/>
                </div>
            )
        })
    }

    return (
        <div className="Account">
            <h3>Мой аккаунт</h3>
            <br/>
            <br/>
            { !accountError ?
                <div>
                    <p>{account.account.accountName}</p>
                    <p>{account.account.role}</p>
                    <p>{account.account.registrationDate}</p>
                    <p>{account.account.emailIsConfirmed}</p>
                    {renderCharacter(account.account.characters)}
                </div>
                : "" }
            <br/>
            <br/>
            <div style={{color: "red"}}>{accountError ? "Ошибка " + accountError : ""}</div>
        </div>
    )
}
