import './Auth.scss'
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {Input} from "../../component/Input/Input";
import Button from "../../component/Button/Button";
import Checkbox from "../../component/Checkbox/Checkbox";
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {
    addPasswordConfirm,
    changeCheckRulesValue,
    authRequest,
    changeValue, checkFormForValid, selectAllAuth, selectAuthStatus, selectAuthUser, selectCheckRulesValue
} from "../../storeFeatures/auth/authSlice";

const isJs = require('is_js');

export const Auth = () => {
    const {username} = useSelector((state) => selectAuthUser(state));
    const {isLoginSuccess, isRegistrationSuccess, authError} = useSelector((state) => selectAuthStatus(state));
    const {authAll}: any = useSelector((state) => selectAllAuth(state));
    const {checkRulesValue}: any = useSelector((state) => selectCheckRulesValue(state));
    const dispatch: any = useDispatch();
    const {authType} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (authType === "registration" && isRegistrationSuccess) {
            navigate("/auth/login");
        }

        if ((authType === "registration" || authType === "login") && isLoginSuccess) {
            navigate("/");
        }
    })

    function onChangeHandler(event: any, controlName: any) {

        const formControls: any = {...authAll.formControls}
        const control: any = {...formControls[controlName]}

        control.value = event.target.value
        control.touched = true
        control.valid = validateControl(control.value, control.validation)

        formControls[controlName] = control
        let isFormValid = true

        Object.keys(formControls).forEach((name) => {
            if (name === "passwordConfirm" && authType === "login") {
                isFormValid = true;
            } else {
                isFormValid = formControls[name].valid && isFormValid
            }
        })

        dispatch(changeValue({
            isFormValid,
            formControls
        }));
    }

    const authHandler = (authTypeAction: any) => {

        // @ts-ignore
        let payloadAuth: any = {};
        payloadAuth["authTypeAction"] = authTypeAction;
        payloadAuth["username"] = authAll.formControls.email.value;
        payloadAuth["password"] = authAll.formControls.password.value;

        if (authTypeAction === "registration") {
            payloadAuth["confirmPassword"] = authAll.formControls.passwordConfirm.value;
            payloadAuth["registerConfirmRules"] = checkRulesValue;
        }

        // @ts-ignore
        dispatch(authRequest({
            payloadAuth
        }));
    }

    const authCheckHandler = (e: any) => {
        let registerConfirmRules = e.target.checked

        // @ts-ignore
        dispatch(changeCheckRulesValue
        ({
            registerConfirmRules
        }));
    }

    function validateControl(value: any, validation: any) {

        if (!validation) {
            return true
        }

        let isValid = true


        if (validation.required) {
            isValid = (value.trim() !== '') && isValid
        }

        if (validation.email) {
            isValid = isJs.email(value)
        }
        if (validation.minLength) {
            isValid = value.length >= validation.minLength && isValid
        }


        return isValid
    }

    return (
        <div className="Auth">

            {authAll && authAll.formControls ? showAuthElement(authType) : null}

            <br/>


            <div>{isRegistrationSuccess ? "Вы успешно зарегистрировались. Необходимо дождаться письма на указанный вами почтовый адрес " + username + " и пройти процедуру подтверждения" : null}</div>
            <div style={{color: "red"}}>{authError}</div>

            <br/>

            {authType === "login" && isLoginSuccess !== true && isRegistrationSuccess!==true ? (
                    <div>
                        <nav>
                            <NavLink onClick={() => addPasswordConfirmForRegistration()} to="/auth/registration"
                                     className="nav-link-auth">Зарегистрироваться</NavLink>
                        </nav>
                    </div>
                )
                : null
            }
        </div>
    )

    function addPasswordConfirmForRegistration() {
        dispatch(addPasswordConfirm({}));
        dispatch(checkFormForValid({}));
    }

    function showAuthElement(authType: any) {
        return (
            <div>
                {authType === "login" ? <h2>Вход</h2> : <h2>Регистрация</h2>}
                {renderInputs()}
                <div className={"Checkbox"}>
                    {authType === "registration" ?  <Checkbox onChange={(e: any) => authCheckHandler(e)} /> : ""}
                </div>
                <br/>
                <Button type="success"
                        onClick={() => authHandler(authType)}
                        disabled={!authAll.isFormValid}>
                    {authType === "login" ? "Войти" : "Регистрация"}
                </Button>
            </div>
        )
    }


    function renderInputs() {
        return Object.keys(authAll.formControls).map((controlName, index) => {
            const control = authAll.formControls[controlName]
            if (controlName === "passwordConfirm" && authType === "login") {
                return null;
            }
            return (
                <Input
                    key={controlName + index}
                    type={control.type}
                    value={control.value}
                    valid={control.valid}
                    touched={control.touched}
                    label={control.label}
                    shouldValidate={!!control.validation}
                    errorMessage={control.errorMessage}
                    onChange={(event: any) => onChangeHandler(event, controlName)}
                />
            )
        })
    }
}

