import React from "react";
import './Navbar.scss'
import {NavLink} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";

import {
    /*checkFormForValid,*/ logout,
    /*removePasswordConfirm,*/
    selectAuthStatus,
} from "../../storeFeatures/auth/authSlice";

export const Navbar = () => {

    const dispatch: any = useDispatch();
    const {isLoginSuccess} = useSelector((state) => selectAuthStatus(state));

    return (
            <nav className="Navbar">
                <NavLink to="/" className="nav-link">Главная</NavLink>
                <NavLink to="/video" className="nav-link">Видео</NavLink>
                <NavLink to="/download" className="nav-link">Играть</NavLink>
                {isLoginSuccess ?
                    <div>
                        <NavLink to="/account" className="nav-link">Аккаунт</NavLink>
                        <NavLink onClick={() => Logout()} to="/" className="nav-link">Выход</NavLink>
                    </div>
                    : <NavLink /*onClick={() => RemovePasswordConfirmForRegistration()}*/ to="/auth/login"
                               className="nav-link">Войти</NavLink>
                }
            </nav>

    )

/*    function RemovePasswordConfirmForRegistration() {
        dispatch(removePasswordConfirm({}));
        dispatch(checkFormForValid({}));
    }*/

    function Logout() {
        dispatch(logout({}));
    }
}